import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import NewsSectionBlock from '../../components/NewsSectionBlock'
import Typography from '../../components/Common/Typography'

import * as s from '../../pages/news.module.scss'

const BimNaStroyke = () => {
  return (
    <>
      <SEO
        title="BIM на стройке: новый стандарт строительства "
        description="Практическое применение BIM на этапе строительства для повышения эффективности, минимизации рисков и снижения затрат"
      />
      <NewsSectionBlock date="26.06.2024">
        <Typography variant="h1" color="blue">
          BIM на стройке: не хайп, а рабочая история
        </Typography>
        <Typography variant="h2" color="blue">
          Введение
        </Typography>
        <Typography variant="body1">
          Цифровизация девелоперов в строительстве главный тренд, направленный
          на повышение эффективности, оптимизацию процессов, а также уменьшение
          сроков строительства. И BIM стал основой этой цифровизации,
          аккумулирующий в себе всю актуальную информацию по проекту в визуально
          наглядном и понятном виде.
        </Typography>
        <Typography variant="body1">
          Но если BIM в проектировании стал уже стандартом отрасли, и ведущие
          проектировщики перешли на данную технологию еще в 15-20х годах,
          которые сегодня используют это как само собой разумеющееся, а
          Застройщики заказывают проекты с BIM и не рассматривают проектные
          компании, не соответствующие их запросу. То на стройке BIM показал
          наибольшую эффективность.
        </Typography>
        <Typography variant="body1">
          Данная технология позволила автоматизированно проверять качество
          модели, быстро собирать объемы работ, связывать их с бюджетом и
          графиком строительства, а также использовать модель для отметки
          предписаний и другой информации на элементах модели, для наглядного
          структурирования информации о ходе реализации проекта.
        </Typography>
        <Typography variant="body1">
          В данной статье рассмотрим подробнее реальные кейсы и эффекты от
          применения данной технологии.
        </Typography>
        <Typography variant="body1">
          Актуальность перехода на современные технологии проектирования и
          строительства поддерживается со стороны государства. С 1 сентября
          2024-го года применение ТИМ (BIM) технологий на гос. объектах и
          строящихся по 214 ФЗ (долевое строительство) станет обязательным
          согласно 614 пост правит от 142.321.342.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/news/bim-na-stroyke/image-1.png"
            alt="BIM модель"
            title="BIM модель"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          10 вариантов использования BIM в строительстве
        </Typography>
        <Typography variant="body1">
          На рынке существует множество решений для работы в BIM, но стандартом
          отрасли на сегодняшний день являются продукты Autodesk - Revit,
          AutoCAD Civil 3D и Navisworks. Данные инструменты хороши для
          проектирования, но для стройки они не полностью подходят. SIGNAL TOOLS
          адаптирует уже привычные и распространенные инструменты для применения
          BIM на стройке.
        </Typography>
        <Typography variant="body1">
          Давайте рассмотрим 10 вариантов и подходов использования BIM на
          объекте строительства:
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>
            BIM-модели улучшают сотрудничество и коммуникацию между участниками
            на объекте за счет использования облачных сервисов, таких как SIGNAL
            DOCS. С помощью мобильных приложений, таких как SIGNAL DASHBOARD,
            участники строительного процесса могут легко получить доступ к
            информации о проекте на своих смартфонах.
          </li>
          <li>
            С помощью BIM-инструментов, таких как Revit, DOCS и SIGNAL TOOLS,
            оценка стоимости строительства на основе моделей автоматизирует
            процесс количественного определения объемов работ и материалов и их
            стоимости. Это позволяет сметчикам сконцентрироваться на более
            ценных факторах.
          </li>
          <li>
            Используя BIM-модели, можно на стадии подготовки осуществить
            планирование и визуализировать его, тем самым снизить необходимость
            дорогостоящих и трудоемких исправлений ошибок в будущем.
          </li>
          <li>
            BIM обеспечивает лучшую координацию и обнаружение конфликтов с
            помощью Navisworks и SIGNAL TOOLS, сокращая количество доработок,
            необходимых для выполнения любого конкретного задания.
          </li>
          <li>
            Инструменты BIM предлагают множество преимуществ экономии средств,
            включая снижение премий за тендерный риск, расходов на страхование,
            изменений и претензий. Кроме того, они минимизируют трудозатраты,
            связанные с подготовкой техзаданий для тендеров и недопониманием.
            Благодаря совместной работе в режиме реального времени и
            централизованному хранилищу документов вероятность использования
            устаревшей информации снижается.
          </li>
          <li>
            BIM экономит время, позволяя одновременно выполнять проектирование и
            подготовку документации, повышая точность планирования и сокращая
            задержки.
          </li>
          <li>
            Использование BIM приводит к повышению производительности и
            сокращению отходов за счет внедрения prefab технологий
          </li>
          <li>
            BIM повышает безопасность строительства, выявляя потенциальные
            опасности и заранее планируя логистику.
          </li>
          <li>
            BIM приводит к повышению качества строительства за счет координации
            участников и возможности раннего выявления структурных недостатков и
            эстетического выбора.
          </li>
          <li>
            BIM улучшает управление зданием и облегчает передачу права
            собственности за счет создания непрерывной цифровой записи данных о
            здании, которую можно использовать для целей после заселения,
            включая потенциальное создание цифровой копии здания.
          </li>
        </ol>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/news/bim-na-stroyke/image-2.png"
            alt="BIM в строительстве"
            title="BIM в строительстве"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Как внедрить BIM на стройке — методология SIGNAL
        </Typography>
        <Typography variant="body1">
          Перед тем как внедрять BIM в компании, надо определить цели и задачи
          применения данной технологии. Т.е. какие эффекты ожидается достичь в
          результате. Приведем пример таких задач и роли участников, кто в этих
          процессах участвует на этапе строительства.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/news/bim-na-stroyke/image-3.png"
            alt="Матрица задач с разбивкой по основным ролям, которые решаются с помощью инструментов SIGNAL"
            title="Матрица задач с разбивкой по основным ролям, которые решаются с помощью инструментов SIGNAL"
            placeholder="blurred"
          />
          <Typography variant="body1" color="gray">
            Матрица задач с разбивкой по основным ролям, которые решаются с
            помощью инструментов SIGNAL
          </Typography>
        </div>
        <Typography variant="body1">
          <strong>Классификация модели</strong> - заполнение кода классификатора
          строительных элементов в параметры элементов модели. Требуется для
          использования BIM-модели на последующих этапах жизненного цикла
          объекта, чтобы остальные участники строительного процесса однозначно
          понимали что обозначает какой элемент в BIM-модели.
        </Typography>
        <Typography variant="body1">
          <strong>Проверка BIM-модели</strong> - автоматизированная проверка
          элементов BIM-модели на заполненность классификатора, наличие в модели
          требуемых элементов и заполненность в них параметров. Автоматизация
          проверки на коллизии и дублирование элементов. Выдача отчета в
          электронном виде исполнителю для исправления в удобном для поиска
          проблемных элементов интерфейсе.
        </Typography>
        <Typography variant="body1">
          <strong>Сбор объемов работ</strong> - Автоматическая выгрузка
          ведомости объемов работ из модели, на любом этапе её реализации, по
          преднастроенным правилам.
        </Typography>
        <Typography variant="body1">
          <strong>Взаимодействие через BCF</strong> - это выдача замечаний и
          заданий на основе 3D вида, 2D планов или Листов непосредственно в
          Revit, либо на основе BIM-модели в Navisworks, либо отдельным
          десктопным приложением при работе с другими сервисами (например
          проверке PDF или Excel). На каждое замечание создается свой чат, в
          котором можно вести переписку, а также к каждому замечанию
          прикрепляются скриншоты с пометками и ссылка на вид и элементы в
          модели.
        </Typography>
        <Typography variant="body1">
          <strong>Отметка замечаний на модели</strong> - в процессе выполнения
          работ на строительной площадке, специалисты строительного контроля
          выявляют дефекты, нарушения технологических операций и выдают другие
          замечания, которые влияют на качество и безопасность будущего объекта.
          Эти замечания привязываются к элементам модели функцией “Комментарии”,
          накапливая таким образом их в модели. При этом все элементы,
          содержащие замечания получают соответствующий статус и раскрашиваются
          в желтый цвет на модели. Затем объемы из этих элементов исключаются из
          подсчета для КС-2 и фиксируются как удержанные. После устранения
          замечаний, элементы получают статус “Принятые” и добавляются в
          следующую КС-2. К элементам также прикрепляются сами документы с
          предписаниями в отсканированном или электронном виде (в формате PDF),
          а в последующем и акты об устранении предписаний.
        </Typography>
        <Typography variant="body1">
          <strong>Фиксация выполнения работ</strong> - Специалисты Генподрядчика
          или Техзаказчика (в зависимости от выбора Заказчика) выделяют элементы
          в модели и отмечают выполнение работы командой SIGNAL TOOLS
          “Завершить”, указав дату выполнения, позицию работы по смете или вид
          работ, а также подрядчика, кто данную работы выполнил.
        </Typography>
        <Typography variant="body1">
          <strong>Визуализация выполнения работ</strong> - Строительная модель,
          на которой отмечается выполнение в формате NWD загружается в BIM 360
          Docs и публикуется в SIGNAL DASHBOARD. Заказчик получает оповещение о
          публикации нового отчета на свой смартфон и может визуально наглядно
          на BIM-модели видеть какие работы были выполнены, используя только
          свой смартфон.
        </Typography>
        <Typography variant="h2" color="blue">
          Схема использования BIM-модели
        </Typography>
        <Typography variant="body1">
          Теперь можно переходить к описанию бизнес-процессов и их отработке на
          пилотном проекте. Затем по опыту пилотного проекта потребуется внести
          правки в процессы и регламентировать, для последующего масштабирования
          технологии на все проекты компании.
        </Typography>
        <Typography variant="body1">
          Схема разделена по ролям пользователей и в цветовой индикации по
          используемым для решения тех или иных задач инструментам.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/news/bim-na-stroyke/image-4.png"
            alt="Схема использования BIM-модели"
            title="Схема использования BIM-модели"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h3">Проектная BIM-модель</Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>Заказчик разрабатывает документ BRD в Excel</li>
          <li>
            Проектировщик подготавливает в Revit BIM-модель, классифицирует её,
            проверяет на соответствие требований EIR, с помощью проверок,
            описанных в BRD.
          </li>
          <li>
            Проектировщик использует модель для получения ведомости объемов
            работ и подготовки Сметы (обычно с помощью Excel или
            специализированных программ).
          </li>
          <li>
            Проектировщик, по графику выдачи Рабочей Документации, выдает и
            соответствующие ей разделы BIM-модели.
          </li>
          <li>
            Заказчик на основе BRD в Navisworks с SIGNAL TOOLS проверяет
            полученную от Проектировщика модель, после чего использует данные из
            нее для подсчета тендерных пакетов и бюджета строительства.
          </li>
          <li>
            Генподрядчик, аналогично заказчику, использует модель (после своей
            проверки) для генерации Календарного сетевого графика и визуализации
            строительства (BIM 4D) в Navisworks и MS Project (или Primavera).
          </li>
        </ol>
        <Typography variant="h3">Строительная BIM-модель</Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>
            Проектировщик разрабатывает проектную BIM-модель и выдает вместе с
            комплектами рабочей документации в PDF. Документация в PDF
            согласуется в среде общих данных SIGNAL DOCS и выдается на
            строительную площадку в производство работ.
          </li>
          <li>
            Проектная BIM-модель подгружается Генподрядчиком как связь в
            Строительную модель в Revit. Далее из проектной модели с помощью
            SIGNAL TOOLS забираются элементы в Строительную модель
            (соответствующие согласованным комплектам РД).
          </li>
          <li>
            Элементы модели нарезаются на захватки в Revit и выгружаются в
            Navisworks.
          </li>
          <li>
            В Navisworks с помощью SIGNAL TOOLS выполняется планирование
            недельно-суточных заданий Генподрядчиком, распределение пакетов
            работ между Субподрядчиками и отметка выполнения работ. По
            результатам идет выгрузка объемов в Excel для отчетов и генерация
            КС-2 и КС-6а.
          </li>
          <li>
            Строительная модель передается Техзаказчику как результат
            выполненных за месяц работ на согласование.
          </li>
          <li>
            Техзаказчик вносит в модель свои предписания и отмечает какие объемы
            рекомендует Заказчику к удержанию. Т.е. отмечает какие объемы
            согласует, а какие нет.
          </li>
          <li>
            Данные из строительной модели от Генподрядчика загружаются вместе с
            самой моделью в Личный кабинет Заказчика в SIGNAL DASHBOARD и
            позволяют Заказчику контролировать процесс реализации проекта и
            освоение бюджета.
          </li>
        </ol>
        <div className={s.imageWrap}>
          <StaticImage
            src="../../assets/images/news/bim-na-stroyke/image-5.png"
            alt="Строительная BIM-модель"
            title="Строительная BIM-модель"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Заключение
        </Typography>
        <Typography variant="body1">
          На сегодняшний день применение BIM на этапе строительства уже норма и
          все больше девелоперов включаются в процесс, требуя все большее
          качество и экспертность от всех участников строительного процесса.
          Умение использовать BIM-технологии в своей повседневной деятельности
          стало неотъемлемым требованием и конкурентным преимуществом.
        </Typography>
        <Typography variant="body1">
          Начиная с грамотного планирования и заканчивая внедрением современных
          цифровых решений, таких как SIGNAL, строительные компании могут
          достичь значительного снижения затрат и улучшения качества своих
          проектов.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default BimNaStroyke
